import { login, logout, getInfo, updateInfo, changePassword, changeWorkoutStartDate, acceptTerms, dontShowIntro } from '@/api/user'
import { executeSubscription, cancelSubscription, createRecurringPayment } from '@/api/subscription'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { removeImpersonate } from '@/utils/impersonate'

const getDefaultState = () => {
  return {
    token: getToken(),
    firstname: '',
    lastname: '',
    name: '',
    avatar: '',
    email: '',
    gender: '',
    ageRange: '',
    weight: '',
    height: '',
    bodyType: '',
    createdOn: 0,
    month: 0,
    day: 0,
    workoutStartDate: "",
    membershipExpiryDate: "",
    frequency: "0",
    isPremiumMember: false,
    hasAcceptedTermsConditions: false,
    hasStoppedNewDesignIntro: false,
    status: "",
    introVideo: "",
    isAdmin: false,
    isTrial: false,
    isSponsored: false,
    trialDays: 0,
    terms: '',
    timezone: '',
    properties: {}

  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_FIRSTNAME: (state, firstname) => {
    state.firstname = firstname
  },
  SET_LASTNAME: (state, lastname) => {
    state.lastname = lastname
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_GENDER: (state, gender) => {
    state.gender = gender
  },
  SET_WEIGHT: (state, weight) => {
    state.weight = weight
  },
  SET_HEIGHT: (state, height) => {
    state.height = height
  },
  SET_AGE_RANGE: (state, ageRange) => {
    state.ageRange = ageRange
  },
  SET_BODY_TYPE: (state, bodyType) => {
    state.bodyType = bodyType
  },
  SET_CREATED_ON: (state, createdOn) => {
    state.createdOn = createdOn
  },
  SET_DAY: (state, day) => {
    state.day = day
  },
  SET_MONTH: (state, month) => {
    state.month = month
  },
  SET_WORKOUT_START_DATE: (state, workoutStartDate) => {
    state.workoutStartDate = workoutStartDate;
  },
  SET_MEMBERSHIP_EXPIRY_DATE: (state, membershipExpiryDate) => {
    state.membershipExpiryDate = membershipExpiryDate;
  },
  SET_FREQUENCY: (state, frequency) => {
    state.frequency = frequency;
  },
  SET_IS_PREMIUM_MEMBER: (state, isPremiumMember) => {
    state.isPremiumMember = isPremiumMember;
  },
  SET_STATUS: (state, status) => {
    state.status = status;

  },
  SET_HAS_ACCEPTED_TERMS_CONDITIONS: (state, hasAcceptedTermsConditions) => {
    state.hasAcceptedTermsConditions = hasAcceptedTermsConditions;
  },
  SET_HAS_STOPPED_NEW_DESIGN_INTRO: (state, hasStoppedNewDesignIntro) => {
    state.hasStoppedNewDesignIntro = hasStoppedNewDesignIntro;
  },

  SET_INTRO_VIDEO: (state, introVideo) => {
    state.introVideo = introVideo;
  },

  SET_IS_ADMIN: (state, isAdmin) => {
    state.isAdmin = isAdmin;
  },
  SET_IS_TRIAL: (state, isTrial) => {
    state.isTrial = isTrial;
  },
  SET_IS_SPONSORED: (state, isSponsored) => {
    state.isSponsored = isSponsored;
  },
  SET_TRIAL_DAYS: (state, trialDays) => {
    state.trialDays = trialDays;
  },
  SET_TERMS: (state, terms) => {
    state.terms = terms;
  },
  SET_TIMEZONE: (state, timezone) => {
    state.timezone = timezone;
  },
  SET_PROPERTIES: (state, properties) => {
    state.properties = properties;
  }

}

const actions = {
  login({ commit }, userInfo) {
    const { username, password, rememberMe } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password, _remember_me: rememberMe }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        removeImpersonate();
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response;

        if (!data) {
          return reject('Verification failed, please Login again.');
        }

        commit('SET_FIRSTNAME', data.firstname);
        commit('SET_LASTNAME', data.lastname);
        commit('SET_NAME', data.name);
        commit('SET_AVATAR', data.avatar);
        commit('SET_EMAIL', data.email);
        commit('SET_AGE_RANGE', data.ageRange);
        commit('SET_GENDER', data.gender);
        commit('SET_WEIGHT', data.weight);
        commit('SET_HEIGHT', data.height);
        commit('SET_BODY_TYPE', data.bodyType);
        commit('SET_CREATED_ON', data.createdOn);
        commit('SET_DAY', data.day);
        commit('SET_MONTH', data.month);
        commit('SET_WORKOUT_START_DATE', data.workoutStartDate);
        commit('SET_MEMBERSHIP_EXPIRY_DATE', data.membershipExpiryDate);
        commit('SET_FREQUENCY', data.frequency);
        commit('SET_IS_PREMIUM_MEMBER', data.isPremiumMember);
        commit('SET_STATUS', data.status);
        commit('SET_HAS_ACCEPTED_TERMS_CONDITIONS', data.hasAcceptedTermsConditions);
        commit('SET_HAS_STOPPED_NEW_DESIGN_INTRO', data.hasStoppedNewDesignIntro);
        commit('SET_INTRO_VIDEO', data.introVideo);
        commit('SET_IS_ADMIN', data.isAdmin);
        commit('SET_IS_TRIAL', data.isTrial);
        commit('SET_IS_SPONSORED', data.isSponsored);
        commit('SET_TRIAL_DAYS', data.trialDays);
        commit('SET_TERMS', data.terms);
        commit('SET_TIMEZONE', data.timezone);
        commit('SET_PROPERTIES', data.properties);
        resolve(data);
      }).catch(error => {
        console.log("could not find user info");
        return reject(error);
      })
    })
  },

  // user logout
  logout() {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  resetToken() {
    return new Promise(resolve => {
      removeToken()
      resolve()
    })
  },

  updateInfo({ commit }, userInfo) {
    const { firstname, lastname } = userInfo;
    return new Promise((resolve, reject) => {
      updateInfo({ firstname: firstname.trim(), lastname: lastname.trim() })
        .then(response => {
          const { data } = response;
          commit('SET_FIRSTNAME', data.firstname);
          commit('SET_LASTNAME', data.lastname);
          commit('SET_NAME', data.name);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
    })

  },

  changePassword({ state }, passwordInfo) {
    console.log("change password action state is", state);
    const { newPassword, oldPassword } = passwordInfo;
    return new Promise((resolve, reject) => {
      changePassword({ newPassword, oldPassword })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error)
        })
    })

  },

  changeWorkoutStartDate({ commit }, info) {
    const { workoutStartDate } = info;
    return new Promise((resolve, reject) => {
      changeWorkoutStartDate({ workoutStartDate })
        .then(response => {
          const { data } = response;
          commit('SET_WORKOUT_START_DATE', data.workoutStartDate);
          commit('SET_DAY', data.day);
          commit('SET_MONTH', data.month);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
    })

  },

  updateHeight({ commit }, userInfo) {
    const { height } = userInfo;
    return new Promise((resolve, reject) => {
      updateInfo({ height: height.trim() })
        .then(response => {
          const { data } = response;
          commit('SET_HEIGHT', data.height);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
    })

  },
  updateWeight({ commit }, userInfo) {
    const { weight } = userInfo;
    return new Promise((resolve, reject) => {
      updateInfo({ weight: weight.trim() })
        .then(response => {
          const { data } = response;
          commit('SET_WEIGHT', data.weight);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
    })
  },

  updateAgeRange({ commit }, userInfo) {
    const { ageRange } = userInfo;
    return new Promise((resolve, reject) => {
      updateInfo({ ageRange: ageRange.trim() })
        .then(response => {
          const { data } = response;
          commit('SET_AGE_RANGE', data.ageRange);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateBodyType({ commit }, userInfo) {
    const { bodyTypeId } = userInfo;
    return new Promise((resolve, reject) => {
      updateInfo({ bodyTypeId: bodyTypeId })
        .then(response => {
          const { data } = response;
          commit('SET_BODY_TYPE', data.bodyType);
          commit('SET_GENDER', data.gender);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateFrequency({ commit }, userInfo) {
    const { frequency } = userInfo;
    return new Promise((resolve, reject) => {
      updateInfo({ frequency })
        .then(response => {
          const { data } = response;
          commit('SET_FREQUENCY', data.frequency);
          resolve(data);
        }).catch(error => {
          console.log("error", error);
          reject(error)
        })
    })
  },
  updateTimezone({ commit }, userInfo) {
    const { timezone } = userInfo;
    return new Promise((resolve, reject) => {
      updateInfo({ timezone })
        .then(response => {
          const { data } = response;
          commit('SET_TIMEZONE', data.timezone);
          resolve(data);
        }).catch(error => {
          console.log("error", error);
          reject(error)
        })
    })
  },
  cancelSubscription({ commit }) {
    return new Promise((resolve, reject) => {
      cancelSubscription()
        .then((response) => {
          let { data } = response;
          commit('SET_IS_PREMIUM_MEMBER', data.isPremiumMember);
          commit('SET_STATUS', data.status);
          commit('SET_MEMBERSHIP_EXPIRY_DATE', data.membershipExpiryDate);
          resolve(response);
        }).catch(error => {
          console.log("error", error);
          reject(error)
        })
    })
  },
  executeSubscription({ commit }, data) {
    let {baToken, agreementId} = data;
    return new Promise((resolve, reject) => {
      executeSubscription({ baToken, agreementId })
        .then((response) => {
          let { data } = response;
          commit('SET_IS_PREMIUM_MEMBER', data.isPremiumMember);
          commit('SET_IS_TRIAL', data.isTrial);
          commit('SET_IS_SPONSORED', data.isSponsored);
          commit('SET_TRIAL_DAYS', data.trialDays);
          commit('SET_WORKOUT_START_DATE', data.workoutStartDate);
          commit('SET_DAY', data.day);
          commit('SET_MONTH', data.month);
          commit('SET_STATUS', data.status);
          commit('SET_MEMBERSHIP_EXPIRY_DATE', data.membershipExpiryDate);
          resolve(response);
        }).catch(error => {
          console.log("error", error);
          reject(error)
        })
    })
  },
  createRecurringPayment({ commit }, data) {
    const card = {
      card_number: data.card_number,
      card_expiry_date: data.card_expiry_date,
      card_cvv: data.card_cvv,
      card_holder_name: data.card_holder_name,
      address: data.address,
      postal_code: data.postal_code,
      plan_id: data.plan_id,
    };
    return new Promise((resolve, reject) => {
      createRecurringPayment(card)
        .then((response) => {
          let { data } = response;
          commit('SET_IS_PREMIUM_MEMBER', true);
          commit('SET_WORKOUT_START_DATE', data.workoutStartDate);
          commit('SET_DAY', data.day);
          commit('SET_MONTH', data.month);
          commit('SET_STATUS', data.status);
          commit('SET_MEMBERSHIP_EXPIRY_DATE', data.membershipExpiryDate);
          resolve(response);
        }).catch(error => {
          console.log("error", error);
          reject(error)
        })
    })
  },

  acceptTerms({ commit }) {
    return new Promise((resolve, reject) => {
      acceptTerms()
        .then(response => {
          let { data } = response;
          commit('SET_HAS_ACCEPTED_TERMS_CONDITIONS', data.hasAcceptedTermsConditions);
          resolve(response);
        }).catch(error => {
          reject(error)
        })
    })

  },

  dontShowIntro({ commit }) {
    return new Promise((resolve, reject) => {
      dontShowIntro()
        .then(response => {
          const { data } = response;
          commit('SET_HAS_STOPPED_NEW_DESIGN_INTRO', data.hasStoppedNewDesignIntro);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
    })

  },
  updateVideoQuality({ commit }, userInfo) {
    const { videoResolution } = userInfo;
    return new Promise((resolve, reject) => {
      updateInfo({ videoResolution })
        .then(response => {
          const { data } = response;
          commit('SET_TIMEZONE', data.timezone);
          commit('SET_PROPERTIES', data.properties);
          resolve(data);
        }).catch(error => {
          console.log("error", error);
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

