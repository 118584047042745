import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/permission'

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
