import request from '@/utils/request';

export function saveWorkoutLog(data, action) {
    return request({
        url: `/api/workout-logs/save?action=${action}`,
        method: 'post',
        data
    });
}

export function getWorkoutLog(data) {
    return request({
        url: `/api/workout-logs/get?day=${data.day}&month=${data.month}`,
        method: 'get'
    });
}

export function getLastWorkoutLog() {
    return request({
        url: `/api/workout-logs/get-last`,
        method: 'get'
    });
}


export function getWorkoutLogList() {
    return request({
        url: `/api/workout-log/list`,
        method: 'get'
    });
}

