import $ from "jquery";

export default function () {
    $(document).ready(function () {
        $(".menuopener").on("click", function (e) {
            e.preventDefault();
            $(".menupopup").fadeIn(200);
            $("body").addClass("modalopen")
        });
        $(".menupopup_close").on("click", function (e) {
            e.preventDefault();
            $(".menupopup").fadeOut(200);
            $("body").removeClass("modalopen")
        });
    });
}