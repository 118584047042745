
<template>
  <footer>
    <div class="footer">
      <div class="footer_location">
        <a target="_blank" href="https://goo.gl/maps/Gjrk8JzeNoswZXHW9"
          ><i class="atuicons atuicons-place"></i>GYM Location</a
        >
      </div>
      <div class="footer_hashtag">
        <a
          target="_blank"
          href="https://www.instagram.com/explore/tags/atighteru/"
          >#ATIGHTERU</a
        >
      </div>
      <div class="footer_social">
        <div class="footer_social_t">follow us</div>
        <ul>
          <li>
            <a target="_blank" href="https://www.facebook.com/ATIGHTERU"
              ><i class="atuicons atuicons-facebook"></i
            ></a>
          </li>
          <li>
            <a target="_blank" href="https://www.instagram.com/atighteru/"
              ><i class="atuicons atuicons-instagramm"></i
            ></a>
          </li>
        </ul>
      </div>
      <div class="footer_bottom">
        <div class="footer_copyright">
          <a class="footer_logo" href="#"></a>© 2015—{{
            new Date() | moment("YYYY")
          }}
          All Right Reserved
        </div>

        <ExternalLinks ulClass="footer_links"></ExternalLinks>
        <div class="footer_top">
          <a href="#">go to top<i class="atuicons atuicons-top"></i></a>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
import ExternalLinks from "./ExternalLinks";
export default {
  name: "Footer",
  components: { ExternalLinks },
};
</script>