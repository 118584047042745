import request from '@/utils/request'

export function getSelectOptions() {
    return request({
        url: `/api/settings/get-select-options`,
        method: 'get',
    })
}

export function getTimezoneList() {
    return request({
        url: `/api/settings/get-timezone-list`,
        method: 'get',
    })
}


