<template>
  <div>
    <Header v-if="isLoggedin"></Header>
    <Navigation v-if="isLoggedin"></Navigation>
    <AppMain></AppMain>
    <Footer v-if="isLoggedin"></Footer>
    <MenuPopup v-if="isLoggedin"></MenuPopup>
  </div>
</template>

<script>
import { AppMain, Navigation, Header, Footer, MenuPopup } from "./components";
import custom_js from "./custom";

export default {
  name: "Layout",
  components: {
    AppMain,
    Navigation,
    Header,
    Footer,
    MenuPopup,
  },
  methods: {
    customjs() {
      custom_js();
    },
  },
  mounted: function () {
    this.customjs();
  },
  computed: {
    isLoggedin() {
      return this.$store.getters.profile.token;
    },
  },
};
</script>

<style scoped>
@import "~@/assets/css/main.css";
@import "~@/assets/css/atuicons.css";
</style>
