import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { getImpersonate } from '@/utils/impersonate'
import swal from 'sweetalert';

function alertForToken() {
  swal({
    title: "Session Expired!",
    text: "You have been logged out, you can cancel to stay on this page, or log in again",
    icon: "warning",
    buttons: ["Cancel", "Re-Login"],
    dangerMode: true,
  })
    .then((willLogin) => {
      if (willLogin) {
        store.dispatch('user/resetToken').then(() => {
          window.location.href = "/";
        })
      }
    });

}

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers.Authorization = `Bearer ${getToken()}`;
    }

    let impersonateUsername = getImpersonate();
    if (impersonateUsername) {
      config.headers["X-Switch-User"] = impersonateUsername;
    }
    return config;
  },
  error => {
    console.log(error);// for debug
    return Promise.reject(error);
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;

    if (res.code >= 200 && res.code < 300) {
      return res;
    }

    return Promise.reject(new Error(res.message || 'Error while processing response in interceptor'))
  },
  error => {
    let errorTitle = "";
    let isSwalDone = false;

    if (error.response && error.response.data) {
      let res = error.response.data;

      if (res.code >= 500 || (error.response.status && error.response.status >= 500)) {
        error.message = "Oops! Something went wrong. Please refresh the page or try again later.";
        errorTitle = "Server Error";
      }

      if (res.code >= 400 && res.code < 500 && res.errors && res.errors.length) {
        error.message = res.errors;
      }

      if (res.code === 401 && res.error_code === "token_expired") {
        error.message = res.message;
        alertForToken();
        isSwalDone = true;
      }

      if (res.code === 401 && res.error_code === "invalid_credentials") {
        errorTitle = "Invalid credentials.";
        error.message = "Username or password is incorrect.";
      }

    } else {
      console.log("error without response", error);
    }

    if (!isSwalDone) {
      swal(errorTitle, error.message, "error");
    }

    return Promise.reject(error);
  }
)

export default service
