import { getSwapExercises } from '@/api/exercise'


const getDefaultState = () => {
    return {
        originalExercise: {},
        exercises: [],
        info: {}
    }
}

const state = getDefaultState()

const mutations = {
    SET_EXERCISES: (state, exercises) => {
        state.exercises = exercises;
    },
    SET_INFO: (state, info) => {
        state.info = info;
    },
    SET_ORIGINAL_EXERCISE: (state, originalExercise) => {
        state.originalExercise = originalExercise;
    },
}

const actions = {
    getSwapExercises({ commit }, info) {
        const exercise = info.exercise;
        const params = { exerciseId: exercise.id, workoutId: info.workoutId, type: info.type, index: info.index };
        return new Promise((resolve, reject) => {
            getSwapExercises(params).then(response => {
                const { data } = response;
                commit('SET_EXERCISES', data.exercises);
                commit('SET_INFO', data.info);
                commit('SET_ORIGINAL_EXERCISE', exercise);
                resolve(data);
            }).catch(error => {
                commit('SET_ORIGINAL_EXERCISE', {});
                console.log("could not find swap exercise list");
                return reject(error);
            })
        })

    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

