import request from '@/utils/request'

export function login(data) {
  return request({
    url: `/api/login`,
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/api/user/info',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/api/logout',
    method: 'post'
  })
}

export function updateInfo(data) {
  return request({
    url: '/api/user/save',
    method: 'post',
    data
  })
}

export function changePassword(data) {
  return request({
    url: '/api/user/change-password',
    method: 'post',
    data
  })
}

export function changeWorkoutStartDate(data) {
  return request({
    url: '/api/user/change-workout-start-date',
    method: 'post',
    data
  })
}

export function acceptTerms() {
  return request({
    url: '/api/user/accept-terms',
    method: 'post'
  })
}


export function dontShowIntro() {
  return request({
    url: '/api/user/dont-show-intro',
    method: 'post'
  })
}
