const getDefaultState = () => {
    return {
        exercise: {},
    }
}

const state = getDefaultState()

const mutations = {
    SET_EXERCISE: (state, exercise) => {
        state.exercise = exercise;
    },
}

const actions = {
    selectExercise({ commit }, exercise) {
        commit('SET_EXERCISE', exercise);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

