import { getSelectOptions } from '@/api/settings'

const getDefaultState = () => {
    return {
        selectOptions: [],
    }
}

const state = getDefaultState()

const mutations = {
    SET_SELECT_OPTIONS: (state, options) => {
        state.selectOptions = options;
    },
}

const actions = {
    getSelectOptions({ commit }) {
        return new Promise((resolve, reject) => {
            getSelectOptions().then(response => {
                const { data } = response;
                commit('SET_SELECT_OPTIONS', data);
                resolve(data);
            }).catch(error => {
                console.log("could not find settings-select options");
                return reject(error);
            })
        })
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

