const getters = {
  token: state => state.user.token,
  name: state => state.user.name,
  profile: state => state.user,
  settings: state => state.settings,
  exerciseDescription: state => state.exerciseDescription,
  swap: state => state.swap,
  lastWorkoutLog: state => state.workoutlog.log,
  avatar: state => {
    let profile = state.user && state.user.gender ?
      state.user.gender.toLowerCase() : "defaultprofilepic";
    return require(`@/assets/img/${profile}.jpg`);
  },
}
export default getters;