import Cookies from 'js-cookie'

const ImpersonateKey = 'atuimpersonate'

export function getImpersonate() {
    return Cookies.get(ImpersonateKey)
}

export function setImpersonate(username) {
    return Cookies.set(ImpersonateKey, username.trim())
}

export function removeImpersonate() {
    return Cookies.remove(ImpersonateKey)
}
