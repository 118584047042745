<template>
  <ul :class="ulClass">
    <li v-for="(link, index) in links" :key="index">
      <a target="_blank" :href="baseUrl + link.href">{{ link.title }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ExternalLinks",
  props: { ulClass: String },
  data() {
    return {
      links: [
        { title: "FAQ", href: "/faqs" },
        { title: "Customer Support", href: "/faqs" },
        { title: "About", href: "/about-us" },
      ],
      baseUrl: process.env.VUE_APP_BASE_API,
    };
  },
};
</script>
