import request from '@/utils/request'


export function createSubscription(data) {
    return request({
        url: `/api/paypal-payment/create-subscription`,
        method: 'post',
        data
    })
}

export function executeSubscription(data) {
    return request({
        url: `/api/paypal-payment/execute-subscription`,
        method: 'post',
        data
    })
}


export function cancelSubscription() {
    return request({
        url: `/api/paypal-payment/cancel-subscription`,
        method: 'post',
    })
}

export function createRecurringPayment(data) {
    return request({
        url: `/api/elavon-payment/create-subscription`,
        method: 'post',
        data
    })
}


