<template>
  <div class="menupopup">
    <div class="menupopup_close"><i class="atuicons atuicons-close"></i></div>
    <div class="menupopup_center">
      <ExternalLinks ulClass="menupopup_links"></ExternalLinks>
    </div>
  </div>
</template>

<script>
import ExternalLinks from "./ExternalLinks";
export default {
  name: "MenuPopup",
  components: { ExternalLinks },
};
</script>
