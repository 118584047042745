
<template>
  <header>
    <div class="header">
      <div class="header_left" style="visibility: hidden">
        <div class="header_menuopener">
          <a class="menuopener" href="#"><span></span><span></span></a>
        </div>
      </div>
      <div class="header_logo"><a class="logo" href="#"></a></div>
      <div class="header_logout">
        <a class="header_logout_a" href="#" @click.prevent="logout">LOG OUT</a
        ><a
          class="header_userphoto"
          href="#"
          testkeyddd="testvalueddd"
          :style="{ backgroundImage: `url(${$store.getters.avatar})` }"
        ></a>
      </div>
    </div>
  </header>
</template>



<script>
export default {
  name: "Header",
  methods: {
    logout() {
      this.$store
        .dispatch("user/logout", this.loginForm)
        .then(() => {
          window.location.href = "/";
        })
        .catch((error) => {
          console.log("could not logout successfully", error);
        });
    },
  },

  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>