import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import workoutlog from './modules/workoutlog'
import settings from './modules/settings'
import exerciseDescription from './modules/exerciseDescription'
import swap from './modules/swap'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    settings,
    exerciseDescription,
    swap,
    workoutlog
  },
  getters
})

export default store
