import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/layout'

export const constantRoutes = [

  {
    path: '/login',
    hidden: true,
    component: Layout,
    children: [{
      path: '',
      name: 'Login',
      component: () => import('@/views/login/index')
    }]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/profile',
    children: [{
      path: 'profile',
      name: 'Profile',
      component: () => import('@/views/profile/index'),
      meta: { title: 'Profile', icon: 'home' }
    }]
  },
  {
    path: '/schedule',
    component: Layout,
    redirect: "/schedule/index",
    children: [
      {
        path: 'index',
        name: 'Schedule',
        component: () => import('@/views/schedule/index'),
        meta: { title: 'Schedule', icon: 'human' }
      }
    ]
  },
  {
    path: '/workout',
    component: Layout,
    redirect: "/workout/index",
    children: [
      {
        path: 'index',
        name: 'Today',
        component: () => import('@/views/workout/index'),
        meta: { title: 'Today', icon: 'dumbbell' }
      }
    ]
  },
  {
    path: '/log',
    component: Layout,
    redirect: "/log/index",
    children: [
      {
        path: 'index',
        name: 'Log',
        component: () => import('@/views/log/index'),
        meta: { title: 'Log', icon: 'clock' }
      }
    ]
  },
  {
    path: '/measurement',
    component: Layout,
    redirect: "/measurement/index",
    children: [
      {
        path: 'index',
        name: 'Progress',
        component: () => import('@/views/measurement/index'),
        meta: { title: 'Progress', icon: 'height' }
      }
    ]
  },
  {
    path: '/workoutlog',
    component: Layout,
    redirect: "/workoutlog/index",
    hidden: true,
    children: [
      {
        path: 'index',
        name: 'Workoutlog',
        component: () => import('@/views/workoutlog/index'),
        meta: { title: 'WorkoutLog', icon: 'clock' }
      }
    ]
  },


  {
    path: '/account',
    component: Layout,
    redirect: "/account/index",
    children: [
      {
        path: 'index',
        name: 'Account',
        component: () => import('@/views/account/index'),
        meta: { title: 'Account', icon: 'settings' }
      }
    ]
  },

  {
    path: '/manual',
    component: Layout,
    redirect: "/manual/index",
    children: [
      {
        path: 'index',
        name: 'Manual',
        component: () => import('@/views/manual/index'),
        meta: { title: 'Manual', icon: 'wlog' }
      }
    ]
  },

];

const createRouter = () => new Router({
  routes: constantRoutes,
  linkActiveClass: "active",
  linkExactActiveClass: "active"
})

const router = createRouter();


export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
