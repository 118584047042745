import { saveWorkoutLog, getLastWorkoutLog } from '@/api/workoutlog'


const getDefaultState = () => {
    return {
        log: { day: 0, month: 0 },
    }
}

const state = getDefaultState()

const mutations = {
    SET_LAST_WORKOUT_LOG: (state, log) => {
        state.log = log;
    },
}

const actions = {
    add({ commit }, data) {
        const { day, month } = data;
        return new Promise((resolve, reject) => {
            saveWorkoutLog({ day, month }, "add").then(({ data }) => {
                commit('SET_LAST_WORKOUT_LOG', { day: data.day, month: data.month });
                resolve();
            }).catch(error => {
                reject(error)
            })
        })
    },
    remove({ commit }, data) {
        const { day, month } = data;
        return new Promise((resolve, reject) => {
            saveWorkoutLog({ day, month }, "remove").then(({ data }) => {
                commit('SET_LAST_WORKOUT_LOG', { day: data.day, month: data.month });
                resolve();
            }).catch(error => {
                reject(error)
            })
        })
    },
    update({ commit }) {
        return new Promise((resolve, reject) => {
            getLastWorkoutLog().then(({ data }) => {
                commit('SET_LAST_WORKOUT_LOG', { day: data.day, month: data.month });
                resolve();
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

