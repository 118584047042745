<template>
  <nav class="main_nav">
    <ul>
      <router-link
        :to="route.redirect ? route.redirect : route.path"
        v-for="route in routes"
        :key="route.path"
        custom
        v-slot="{ navigate, isActive, href }"
      >
        <li :class="{ active: isActive }">
          <a :href="href" @click="navigate"
            ><i :class="`atuicons atuicons-${route.children[0].meta.icon}`"></i
            ><span>{{
              route.children ? route.children[0].meta.title : "ATU Mobile"
            }}</span></a
          >
        </li>
      </router-link>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  computed: {
    routes() {
      let routelinks = this.$router.options.routes;
      routelinks = routelinks.filter((link) => {
        return link.hidden !== true;
      });
      return routelinks;
    },
  },
};
</script>
